import React, { Component } from 'react';
import { Webcam } from 'src/utils/webcam';
// import './ClCamera.css';
import Api from 'src/utils/api'

class ClCamera extends Component {
    constructor() {
        super();
        this.webcam = null;
        this.state = {
            capturedImage: null,
            captured: false,
            uploading: false
        }
    }

    componentDidMount() {
        // initialize the camera
        this.canvasElement = document.createElement('canvas');
        this.webcam = new Webcam(
            document.getElementById('webcam'),
            this.canvasElement
        );
        this.webcam.setup().catch(() => {
            alert('Error getting access to your camera');
        });
    }

    componentDidUpdate(prevProps) {
        if (!this.props.offline && (prevProps.offline === true)) {
            // if its online,
            this.batchUploads();
        }
    }

    render() {
        const imageDisplay = this.state.capturedImage ?
            <img src={this.state.capturedImage} alt="captured" width="350" />
            :
            <span />;

        const buttons = this.state.captured ?
            <div>
                <button className="deleteButton" onClick={this.discardImage} > Delete Photo </button>
                <button className="captureButton" onClick={this.uploadImage} > Upload Photo </button>
            </div> :
            <button className="captureButton" onClick={this.captureImage} > Take Picture </button>

        const uploading = this.state.uploading ?
            <div><p> Uploading Image, please wait ... </p></div>
            :
            <span />

        return (
            <div className="row">
                { uploading && (<div className="col-12">{uploading}</div>)}
                <div className="col-6">
                  <video autoPlay playsInline muted id="webcam" width="100%" height="200" />
                </div>
                <div className="col-6">
                 {imageDisplay}
                </div>
                
                <div className="col-12"><div className="justify-content-center">{buttons}</div></div>
            </div>
        )
    }

    captureImage = async () => {
        try{
          const capturedData = this.webcam.takeBase64Photo({ type: 'jpeg', quality: 0.8 });
          if(capturedData.base64 ===  'data:,'){
            throw new Error('Please allow camera acces on your device')
          }

          console.log(capturedData);
          this.setState({
              captured: true,
              capturedImage: capturedData.base64
          });
          
        }catch(error){
          alert(error)
        }
        
    }

    discardImage = () => {
        this.setState({
            captured: false,
            capturedImage: null
        })
    }

    uploadImage = () => {
        if (this.props.offline) {
            console.log("you're using in offline mode sha");
            // create a random string with a prefix
            const prefix = 'cloudy_pwa_';
            // create random string
            const rs = Math.random().toString(36).substr(2, 5);
            localStorage.setItem(`${prefix}${rs}`, this.state.capturedImage);
            alert('Image saved locally, it will be uploaded to your Fake Cloud media library once internet connection is detected');
            this.discardImage();
            // save image to local storage
        } else {
            this.setState({ 'uploading': true });
            Api.post(
                '/server.php',
                {
                    file: this.state.capturedImage,
                    upload_preset: process.env.REACT_APP_CLOUD_PRESET
                }
            ).then(
                (data) => this.checkUploadStatus(data)
            )
                .catch((error) => {
                    alert('Sorry, we encountered an error uploading your image');
                    this.setState({ 'uploading': false });
                });
        }
    }

    findLocalItems = (query) => {
        var i, results = [];
        for (i in localStorage) {
            if (localStorage.hasOwnProperty(i)) {
                if (i.match(query) || (!query && typeof i === 'string')) {
                    const value = localStorage.getItem(i);
                    results.push({ key: i, val: value });
                }
            }
        }
        return results;
    }

    checkUploadStatus = (data) => {
        this.setState({ 'uploading': false });
        if (data.status === 200) {
          if(typeof this.props.callBackRefresh === 'function')
          {
            this.props.callBackRefresh()
          }
            alert('Image Uploaded to Fake Cloud Media Library');
            this.discardImage();
        } else {
            alert('Sorry, we encountered an error uploading your image');
        }
    }
    batchUploads = () => {
        // this is where all the images saved can be uploaded as batch uploads
        const images = this.findLocalItems(/^cloudy_pwa_/);
        let error = false;
        if (images.length > 0) {
            this.setState({ 'uploading': true });
            for (let i = 0; i < images.length; i++) {
                // upload
                Api.post(
                    '/server.php',
                    {
                        file: images[i].val,
                        upload_preset: process.env.REACT_APP_CLOUD_PRESET
                    }
                ).then((data) => this.checkUploadStatus(data)).catch((error) => {
                    error = true;
                })
            }
            this.setState({ 'uploading': false });
            if (!error) {
                alert("All saved images have been uploaded to your Fake Cloud Media Library");
            }
        }
    }
}
export default ClCamera;
