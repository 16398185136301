import React from 'react'

export default function( props={} ){
  const message = props.offline ?
        `
        Image Uploader is offline! Your images will be saved now and then uploaded to
        your Fake Cloud Media Library once your internet connection is back up.
    ` :
        `
        Take a picture and it will be uploaded to your Fake Cloud Media Library.
    `;
    return (
        <div className={`alert  ${props.offline ? 'alert-danger' : 'alert-success' } `}>
            <p>
                <em>{message}</em>
            </p>
        </div>
    );
}

