import React,{useState, useEffect} from 'react';
// import logo from './logo.svg';
import './App.css';
import Notif from 'src/components/notif'
import Camera from 'src/components/camera'
import Api from 'src/utils/api'

function App() {
  const [offline,setOffline]=useState(false);
  const [loading,setLoading]=useState(true);
  const [files,setFiles]=useState([]);

  const refreshList = function(){
    Api.get('/get.php').then(({data})=>{
      setLoading(false)
      setFiles(data)
    }).catch(error => alert(error))
  }

  useEffect(function(){
    window.addEventListener('online', () => {
      setOffline(false);
    });

    window.addEventListener('offline', () => {
      setOffline(true);
    });
    
    refreshList()

  },[])
  return (
    <div className="App container">
      <Notif  offline={offline}/>
      <Camera offline={offline} callBackRefresh={refreshList}></Camera>
      <div className="row mt-3">
        { loading && (
          <div className="alert alert-info">
            <strong>Loading image list ....</strong>
          </div>
        )}
        { ( files && files instanceof Array) && files.map((val,k)=>(
          <div className="col-4" key={`img-${k}`}>
            <div className=" p-1">
            <img src={val} alt="" className="img-fluid" />
            </div>
           
          </div>
        )) }
      </div>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
}

export default App;
